import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const LockIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.66514 11C8.66514 10.4477 8.21743 9.99999 7.66514 9.99999C7.11286 9.99999 6.66514 10.4477 6.66514 11H8.66514ZM6.66514 12.6667C6.66514 13.2189 7.11286 13.6667 7.66514 13.6667C8.21743 13.6667 8.66514 13.2189 8.66514 12.6667H6.66514ZM11.0001 7.66667V8.66667C11.5524 8.66667 12.0001 8.21895 12.0001 7.66667H11.0001ZM4.33344 7.66667H3.33344C3.33344 8.21895 3.78115 8.66667 4.33344 8.66667V7.66667ZM6.66514 11V12.6667H8.66514V11H6.66514ZM2.66667 17H12.6667V15H2.66667V17ZM12.6667 17C14.1395 17 15.3333 15.8061 15.3333 14.3333H13.3333C13.3333 14.7015 13.0349 15 12.6667 15V17ZM15.3333 14.3333V9.33331H13.3333V14.3333H15.3333ZM15.3333 9.33331C15.3333 7.86052 14.1395 6.66664 12.6667 6.66664V8.66664C13.0349 8.66664 13.3333 8.96509 13.3333 9.33331H15.3333ZM12.6667 6.66664H2.66667V8.66664H12.6667V6.66664ZM2.66667 6.66664C1.19392 6.66664 0 7.86051 0 9.33331H2C2 8.9651 2.29847 8.66664 2.66667 8.66664V6.66664ZM0 9.33331V14.3333H2V9.33331H0ZM0 14.3333C0 15.8061 1.19392 17 2.66667 17V15C2.29847 15 2 14.7015 2 14.3333H0ZM12.0001 7.66667V4.33333H10.0001V7.66667H12.0001ZM12.0001 4.33333C12.0001 1.94009 10.06 0 7.66677 0V2C8.95541 2 10.0001 3.04467 10.0001 4.33333H12.0001ZM7.66677 0C5.27353 0 3.33344 1.9401 3.33344 4.33333H5.33344C5.33344 3.04467 6.3781 2 7.66677 2V0ZM3.33344 4.33333V7.66667H5.33344V4.33333H3.33344ZM4.33344 8.66667H11.0001V6.66667H4.33344V8.66667Z" />
    </svg>
  );
};

export default LockIcon;
